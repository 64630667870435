// Copyright © 2021 Move Closer

import { IConnector, Injectable, IResponse, Payload } from '@movecloser/front-core'

import { ISiteService } from '@core'

import { IIdentityService } from '@service/identity'

import { ISetupService } from './setup.contracts'
import { SetupFailed } from './setup.exceptions'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Injectable()
export class SetupService implements ISetupService {
  private readonly connector: IConnector
  private readonly identityService: IIdentityService
  private readonly siteService: ISiteService
  private routing: ISetupService['staticRouting'] = []

  public constructor (
    connector: IConnector,
    identityService: IIdentityService,
    siteService: ISiteService
  ) {
    this.connector = connector
    this.identityService = identityService
    this.siteService = siteService
  }

  /**
   * @inheritDoc
   */
  public get currentLocale (): ISetupService['currentLocale'] {
    return this.siteService.getActiveSite().locale
  }

  public get staticRouting (): ISetupService['staticRouting'] {
    return this.routing
  }

  /**
   * @inheritDoc
   */
  public async initApp (entryUrl: string, isClient: boolean): Promise<void> {
    isClient ? await this.initClientApp(entryUrl) : await this.initSSRApp(entryUrl)
  }

  /**
   * @inheritDoc
   */
  public get isMaintenanceMode (): ISetupService['isMaintenanceMode'] {
    return this.siteService.getActiveSite().isMaintenanceMode()
  }

  /**
   * @inheritDoc
   */
  private async callApi (entryUrl: string): Promise<Payload> {
    const response: IResponse = await this.connector.call(
      'setup', 'init', {}, { referer: entryUrl }
    )

    if (!response.isSuccessful()) {
      throw new SetupFailed(
        response.errors?.message || 'Unknown error',
        response.status
      )
    }

    return response.data
  }

  /**
   * Init SSR app with all required setup.
   */
  private async initSSRApp (entryUrl: string): Promise<void> {
    const data = await this.callApi(entryUrl)

    this.setBasicServices(data)
  }

  /**
   * Init Client app with all required setup.
   */
  private async initClientApp (entryUrl: string): Promise<void> {
    const data = await this.callApi(entryUrl)

    this.setBasicServices(data)
    this.identityService.generateSignature()
  }

  /**
   * Sets basic properties.
   */
  private setBasicServices (data: Payload): void {
    this.siteService.setActive(data.site)
    this.siteService.setSites(data.sites)
    this.siteService.setProperty('freeDeliveryValue', data.freeDeliveryEyeCatcher)
    this.siteService.setProperty('search', data.search)
    this.siteService.setProperty('shippingTimer', data.shippingTimer)
    this.siteService.setProperty('productRecommendations', data.productRecommendations)
    this.siteService.setProperty('blogUrl', data.blogUrl ?? '/porady-kosmetyczne') // todo
    this.siteService.setProperty('discounts', data.discounts ?? { milesAndMore: 0, friendsAndFamily: 0, kameleon: 0, newsletter: 0 })

    this.routing = data.staticRouting
  }
}
